<template>
  <div class="right-content px-4 pt-4">

    <div class="c--breadcrumb" :class="{ to_move: $sidebar.isMinimized }">
      <router-link to="/details/test-explorer/list-tests"><i class="el-icon-house"></i></router-link>
      <span><i class="el-icon-d-arrow-right mx-1 cb--divider"></i></span>
      <span>{{ $t('subscription_view.subscription') }}</span>
    </div>

    <div class="row" v-if="redirectBackFromStripe.operation && redirectBackFromStripe.status">
      <div class="col">
        <p>
          <base-alert :type="redirectBackFromStripe.alertType" dismissible>
            <span class="alert-inner--icon">
              <i class="ni ni-money-coins"></i>
            </span> <span class="alert-inner--text">
              {{ formatStripeRedirectMessage() }}
            </span>
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </base-alert>
        </p>
      </div>
    </div>

    <div class="row" v-if="!loading">
      <div class="col">
        <div class="card">
          <div class="card-header">
            <h1>{{ $t('subscription_view.current_plan') }}</h1>
          </div>
          <div class="card-body">
            <div class="row">

              <div class="col col-xl-6 col-md-6 order-xl-1" v-if="tierUsage !== undefined && tierUsage !== null">
                <table class="table table-striped table-sm">
                  <tbody>
                    <tr>
                      <th class="white-space-normal">Tier</th>
                      <td class="white-space-normal">{{ user.tierName }}</td>
                    </tr>
                    <tr>
                      <th class="white-space-normal">Enabled services</th>
                      <td class="white-space-normal">{{ getServiceEntitlements }}</td>
                    </tr>
                    <tr v-if="getTrialPeriodDays !== 'unlimited'">
                      <th class="white-space-normal">Trial period [days]</th>
                      <td class="white-space-normal">{{ getTrialPeriodDays }}</td>
                    </tr>
                    <tr>
                      <th class="white-space-normal">Hosted run max duration [seconds]</th>
                      <td class="white-space-normal">{{ tierLimits.maxTestRunTimeSec }}</td>
                    </tr>
                    <tr v-if="tierLimits.userActionsAllowedServices.indexOf('pageview-events') !== -1">
                      <th class="white-space-normal">App live-event projects</th>
                      <td class="white-space-normal">{{ tierLimits.maxTestBaseUrls }}</td>
                    </tr>
                  </tbody>
                </table>

                <details class="mt-4">
                  <summary class="h3">Plan usage limits</summary>
                  <table class="table table-striped table-sm">
                    <tbody>
                      <tr>
                        <th class="white-space-normal">Visible recent test runs</th>
                        <td class="white-space-normal">{{ getMaxVisibleTestRuns }}
                        </td>
                      </tr>
                      <tr>
                        <th class="white-space-normal">API processed test runs per day</th>
                        <td class="white-space-normal">{{ tierLimits.maxTestRunUnitsPerDay }}</td>
                      </tr>
                      <tr>
                        <th class="white-space-normal">API upload size limit/request [MB]</th>
                        <td class="white-space-normal">{{ tierLimits.maxUploadSizeBytes / (1024 * 1024) }}</td>
                      </tr>
                      <tr>
                        <th class="white-space-normal">API upload cumulative storage [MB]</th>
                        <td class="white-space-normal">{{ tierLimits.maxTestConfigStoredBytes / (1024 * 1024) }}</td>
                      </tr>
                      <tr>
                        <th class="white-space-normal">API processing minutes per day</th>
                        <td class="white-space-normal">{{ tierLimits.maxTestRunMinutesPerDay }}</td>
                      </tr>
                      <tr>
                        <th class="white-space-normal">Healed selectors are returned</th>
                        <td class="white-space-normal">{{ tierLimits.testResultsShowHealedSelector }}</td>
                      </tr>
                      <tr>
                        <th class="white-space-normal">Visible healed selectors</th>
                        <td class="white-space-normal">{{ getVisibleHealedSelectors }}
                        </td>
                      </tr>
                      <tr>
                        <th class="white-space-normal">Heal-candidate elements are returned</th>
                        <td class="white-space-normal">{{ tierLimits.testResultsShowCandidateElement }}</td>
                      </tr>
                      <tr>
                        <th class="white-space-normal">Untrained healed selectors are returned</th>
                        <td class="white-space-normal">{{ tierLimits.testResultsShowSuggestedSelector }}</td>
                      </tr>
                      <tr>
                        <th class="white-space-normal">Visible untrained healed selectors</th>
                        <td class="white-space-normal">{{ getVisibleSuggestedSelectors }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </details>
              </div>

              <div class="col col-xl-6 col-md-6 order-xl-2" v-if="tierUsage !== undefined && tierUsage !== null">

                <div v-if="tierLimits.userActionsTrialDays > 0">
                  <div class="row">
                    <div class="col">
                      <h3>Trial period</h3>
                      <div class="row">
                        <div class="col col-xl-5">
                          <meter id="trial-period-usage" min="0" :max="tierLimits.userActionsTrialDays"
                            :value="getUsedTrialPeriod">
                          </meter>
                        </div>
                        <div class="col col-xl-7">
                          <label for="trial-period-usage">used {{ getUsedTrialPeriod }} / max
                            {{ tierLimits.userActionsTrialDays }} days</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row" v-if="tierLimits.userActionsAllowedServices.indexOf('pageview-events') !== -1">
                  <div class="col">
                    <h3>App live-event projects</h3>
                    <div class="row">
                      <div class="col col-xl-5">
                        <meter id="app-url-usage" min="0" :max="tierLimits.maxTestBaseUrls"
                          :value="tierUsage.TestBaseUrls">
                        </meter>
                      </div>
                      <div class="col col-xl-7">
                        <label for="app-url-usage">used {{ tierUsage.TestBaseUrls }} / max
                          {{ tierLimits.maxTestBaseUrls }}</label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mt-1">
                  <div class="col">
                    <h3>API upload cumulative storage</h3>
                    <div class="row">
                      <div class="col col-xl-5">
                        <meter id="api-cumulative-storage" min="0" :max="tierLimits.maxTestConfigStoredBytes"
                          :value="tierUsage.TestConfigStoredBytes">
                        </meter>
                      </div>
                      <div class="col col-xl-7">
                        <label for="api-cumulative-storage">used
                          {{ (tierUsage.TestConfigStoredBytes / (1024 * 1024)).toFixed(1) }} / max
                          {{ (tierLimits.maxTestConfigStoredBytes / (1024 * 1024)).toFixed(1) }} MB</label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mt-1">
                  <div class="col">
                    <details>
                      <summary class="h3">API processed test runs per day</summary>
                      <div v-for="(value, key) in tierUsage.TestRunUnitsPerDay" :key="key">
                        <div class="row">
                          <div class="col-xl-3">
                            {{ key }}
                          </div>
                          <div class="col-xl-4">
                            <meter min="0" :max="tierLimits.maxTestRunUnitsPerDay" :value="value">
                            </meter>
                          </div>
                          <div class="col-xl-5">
                            <label>used {{ value }} / max {{ tierLimits.maxTestRunUnitsPerDay }}</label>
                          </div>
                        </div>
                      </div>
                    </details>
                  </div>
                </div>

                <div class="row mt-1">
                  <div class="col">
                    <details>
                      <summary class="h3">API processing minutes per day</summary>
                      <div v-for="(value, key) in tierUsage.TestRunMinutesPerDay" :key="key">
                        <div class="row">
                          <div class="col-xl-3">
                            {{ key }}
                          </div>
                          <div class="col-xl-4">
                            <meter min="0" :max="tierLimits.maxTestRunMinutesPerDay" :value="value">
                            </meter>
                          </div>
                          <div class="col-xl-5">
                            <label>used {{ value.toFixed(1) }} / max {{
                              tierLimits.maxTestRunMinutesPerDay.toFixed(1)
                            }}</label>
                          </div>
                        </div>
                      </div>
                    </details>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="card">

          <div class="card-header">

            <div class="row mx-0">
              <base-button class="subplan-tab-button-first"
                :type="planTabs.active === 'change-plan' ? 'primary' : 'secondary'"
                @click="planTabs.active = 'change-plan'">
                Manage subscription
              </base-button>
              <base-button class="subplan-tab-button" :type="planTabs.active === 'plan-log' ? 'primary' : 'secondary'"
                @click="planTabs.active = 'plan-log'">
                View subscription events
              </base-button>
            </div>

          </div>

          <div class="card-body" v-if="!loading && allPlans !== null">

            <div v-if="planTabs.active === 'change-plan'" class="row">
              <div class="col">

                <div class="row mt-2">
                  <div class="col">
                    <table class="table pricing-table text-center table-borderless table-sm">
                      <thead>
                        <tr>
                          <td class="white-space-normal">
                          </td>
                          <td v-for="(planDetails, planLabel) in allPlans" :key="planLabel" class="white-space-normal">
                            <span v-if="planLabel === user.tierName" class="sub-current-plan-label"><i
                                class="ni ni-bold-down"></i> Your plan <i class="ni ni-bold-down"></i></span>
                            <span v-else></span>
                          </td>
                        </tr>
                        <tr>
                          <th class="white-space-normal">
                          </th>
                          <th v-for="(planDetails, planLabel) in allPlans" :key="planLabel" class="white-space-normal">
                            {{ getPlanName(planLabel) }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(featureDesc, featureLabel) in planFeatures" :key="featureLabel">
                          <th class="white-space-normal" scope="row">
                            {{ featureDesc }}
                          </th>
                          <td v-for="(planDetails, planLabel) in allPlans" :key="planLabel" class="white-space-normal"
                            :class="planLabel === user.tierName ? 'active-plan' : ''">
                            {{ formatPlanFeature(featureLabel, planDetails[featureLabel], planLabel) }}
                          </td>
                        </tr>
                        <tr>
                          <th class="white-space-normal">
                          </th>
                          <td v-for="(planDetails, planLabel) in allPlans" :key="planLabel" class="white-space-normal">
                            <base-button type="button" class="btn btn-primary"
                              @click.prevent="(event) => openContactUs(event)">
                              Contact us
                            </base-button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

              </div>
            </div>

            <div v-else class="row mt-4">
              <div class="col">
                <table class="table table-striped table-sm" v-if="Object.keys(subscriptionEvents).length > 0">
                  <thead>
                    <tr>
                      <th class="white-space-normal">
                        Timestamp
                      </th>
                      <th class="white-space-normal">
                        Event
                      </th>
                      <th class="white-space-normal">
                        Details
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(eventInfo, eventTimestamp) in subscriptionEvents" :key="eventTimestamp">
                      <td class="white-space-normal" v-if="eventInfo.details.level_num >= 2">
                        {{ formatSubscriptionEventTimestamp(eventTimestamp) }}
                      </td>
                      <td class="white-space-normal" v-if="eventInfo.details.level_num >= 2">
                        {{ eventInfo.details.text ? eventInfo.details.text : eventInfo.event }}
                      </td>
                      <td class="white-space-normal" v-if="eventInfo.details.level_num >= 2">
                        <span
                          v-if="eventInfo.event.indexOf('invoice') !== -1 && eventInfo.details.latest_invoice_url !== undefined && eventInfo.details.latest_invoice_url !== null">
                          <a :href="eventInfo.details.latest_invoice_url" rel="noreferrer noopener" target="_blank">View
                            invoice</a>
                        </span>
                        <span v-else></span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p v-else>No subscription events yet.</p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <!--    modals go here-->
    <modal :show.sync="modals.unpaidFreeToPaid">
      <template slot="header">
        <h5 class="modal-title" id="billing-checkout-modal">Add billing information and switch to a paid plan</h5>
      </template>
      <div class="row">
        <div class="col">
          <p class="modal-text">
            We partner with <a target="_blank" href="https://stripe.com" rel="noopener noreferrer">Stripe</a>
            to securely manage subscription plan billing and checkout. Click on the button below to
            proceed to Stripe Checkout and add billing information for your chosen plan.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <base-input placeholder="Name of plan" label="Selected subscription plan" v-model="checkout.planName"
            readonly="true">
          </base-input>
        </div>
      </div>
      <template slot="footer">
        <base-button type="primary" :disabled="checkout.loading" @click.prevent="(event) => handleCheckoutButton(event)">
          Continue to Stripe Checkout
        </base-button>
      </template>
    </modal>

    <modal :show.sync="modals.unpaidOtherToPaid">
      <template slot="header">
        <h5 class="modal-title" id="billing-checkout-modal">Add billing information and switch to a paid plan</h5>
      </template>
      <div class="row">
        <div class="col">
          <p class="modal-text">
            We partner with <a target="_blank" href="https://stripe.com" rel="noopener noreferrer">Stripe</a>
            to securely manage subscription plan billing and checkout. Click on the button below to
            proceed to Stripe Checkout and add billing information for your chosen plan.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <base-input placeholder="Name of plan" label="Selected subscription plan" v-model="checkout.planName"
            readonly="true">
          </base-input>
        </div>
      </div>
      <template slot="footer">
        <base-button type="primary" :disabled="checkout.loading" @click.prevent="(event) => handleCheckoutButton(event)">
          Continue to Stripe Checkout
        </base-button>
      </template>
    </modal>

    <modal :show.sync="modals.unpaidOtherToFree">
      <template slot="header">
        <h5 class="modal-title" id="billing-checkout-modal">Switch to the free Community plan</h5>
      </template>
      <div class="row">
        <div class="col">
          <p class="modal-text">
            Click on the button below to switch your subscription to the free Community plan.
          </p>
        </div>
      </div>
      <template slot="footer">
        <base-button type="primary" @click.prevent="(event) => handleUnpaidOtherToFreeButton(event)">
          Switch to the free Community Plan
        </base-button>
      </template>
    </modal>

    <modal :show.sync="modals.paidFreeToOther">
      <template slot="header">
        <h5 class="modal-title" id="manage-plan-modal">Switch to a paid plan</h5>
      </template>
      <div class="row">
        <div class="col">
          <p class="modal-text">
            We partner with <a target="_blank" href="https://stripe.com" rel="noopener noreferrer">Stripe</a>
            to manage subscription plan billing. Click on the button below to proceed to your
            secure billing portal where you can view invoices, update your purchased seat quantity,
            manage billing information, and change your subscription plan.
          </p>
        </div>
      </div>
      <template slot="footer">
        <base-button type="primary" @click.prevent="(event) => handleManageButton(event)">
          Continue to Stripe Billing Portal
        </base-button>
      </template>
    </modal>

    <modal :show.sync="modals.paidOtherToPaid">
      <template slot="header">
        <h5 class="modal-title" id="manage-plan-modal">Switch to another paid plan</h5>
      </template>
      <div class="row">
        <div class="col">
          <p class="modal-text">
            We partner with <a target="_blank" href="https://stripe.com" rel="noopener noreferrer">Stripe</a>
            to manage subscription plan billing. Click on the button below to proceed to your
            secure billing portal where you can view invoices, update your purchased seat quantity,
            manage billing information, and change your subscription plan.
          </p>
        </div>
      </div>
      <template slot="footer">
        <base-button type="primary" @click.prevent="(event) => handleManageButton(event)">
          Continue to Stripe Billing Portal
        </base-button>
      </template>
    </modal>

    <modal :show.sync="modals.paidOtherToFree">
      <template slot="header">
        <h5 class="modal-title" id="manage-plan-modal">Switch to the free Community plan</h5>
      </template>
      <div class="row">
        <div class="col">
          <p class="modal-text">
            We partner with <a target="_blank" href="https://stripe.com" rel="noopener noreferrer">Stripe</a>
            to manage subscription plan billing. Click on the button below to proceed to your
            secure billing portal where you can cancel your paid subscription plan. This will switch your
            subscription plan to the free <strong>Community</strong> plan.
          </p>
        </div>
      </div>
      <template slot="footer">
        <base-button type="primary" @click.prevent="(event) => handleManageButton(event)">
          Continue to Stripe Billing Portal
        </base-button>
      </template>
    </modal>

    <modal :show.sync="modals.unpaidOtherManage">
      <template slot="header">
        <h5 class="modal-title" id="manage-plan-modal">Add billing information for your current plan</h5>
      </template>
      <div class="row">
        <div class="col">
          <p class="modal-text">
            We partner with <a target="_blank" href="https://stripe.com" rel="noopener noreferrer">Stripe</a>
            to securely manage subscription plan billing and checkout. Click on the button below to
            proceed to Stripe Checkout and add billing information for your current plan.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <base-input placeholder="Name of plan" label="Selected subscription plan" v-model="checkout.planName"
            readonly="true">
          </base-input>
        </div>
      </div>
      <template slot="footer">
        <base-button type="primary" :disabled="checkout.loading" @click.prevent="(event) => handleCheckoutButton(event)">
          Continue to Stripe Checkout
        </base-button>
      </template>
    </modal>

    <modal :show.sync="modals.unpaidFreeManage">
      <template slot="header">
        <h5 class="modal-title" id="billing-checkout-modal">Add billing information and switch to a paid plan</h5>
      </template>
      <div class="row">
        <div class="col">
          <p class="modal-text">
            You are currently on the free Community plan. To switch to another subscription plan,
            fill out the form below. We partner with
            <a target="_blank" href="https://stripe.com" rel="noopener noreferrer">Stripe</a>
            to securely manage subscription plan billing and checkout. Click on the button below to
            proceed to Stripe Checkout and add billing information and switch to a paid plan.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <base-input label="Selected subscription plan">
            <select class="form-control" v-model="checkout.planName">
              <option v-for="(planDetails, planLabel) in allPlans" :key="planLabel" :value="planLabel">
                {{ getPlanName(planLabel) }}
              </option>
            </select>
          </base-input>
        </div>
      </div>
      <template slot="footer">
        <base-button type="primary" :disabled="checkoutButtonDisabled"
          @click.prevent="(event) => handleCheckoutButton(event)">
          Continue to Stripe Checkout
        </base-button>
      </template>
    </modal>

    <modal :show.sync="modals.paidOtherManage">
      <template slot="header">
        <h5 class="modal-title" id="manage-plan-modal">Manage your current plan</h5>
      </template>
      <div class="row">
        <div class="col">
          <p class="modal-text">
            We partner with <a target="_blank" href="https://stripe.com" rel="noopener noreferrer">Stripe</a>
            to manage subscription plan billing. Click on the button below to proceed to your
            secure billing portal where you can view invoices, update your purchased seat quantity,
            manage billing information, and change your subscription plan.
          </p>
        </div>
      </div>
      <template slot="footer">
        <base-button type="primary" @click.prevent="(event) => handleManageButton(event)">
          Continue to Stripe Billing Portal
        </base-button>
      </template>
    </modal>

    <modal :show.sync="modals.contactUs">
      <template slot="header">
        <h5 class="modal-title" id="manage-plan-modal">Contact us</h5>
      </template>
      <div class="row">
        <div class="col">
          <p class="modal-text">
            Please email us at <a href="mailto:support@wring.dev">support@wring.dev</a> for subscription plan changes.
          </p>
        </div>
      </div>
    </modal>

  </div>
</template>

<script>
import axios from "axios";
import moment from "moment-timezone";

export default {
  components: {
  },
  //
  // data
  //
  data() {
    return {
      loading: false,
      user: null,
      tierLimits: null,
      tierUsage: null,
      stripe: null,
      subscriptionEvents: null,
      allPlans: null,
      planFeatures: {
        userActionsAllowedServices: "Enabled services",
        // userActionsTrialDays: "Trial period [days]",
        maxTestRunTimeSec: "Hosted run max duration [seconds]",
        maxTestBaseUrls: "App live-event projects",
        testResultsMaxVisibleRuns: "Visible recent test runs",
        maxTestRunUnitsPerDay: "API processed test runs per day",
        maxUploadSizeBytes: "API upload size limit per request [MB]",
        maxTestConfigStoredBytes: "API upload cumulative storage [MB]",
        maxTestRunMinutesPerDay: "API processing minutes per day"
      },
      modals: {
        // new stripe customer switching plans
        unpaidFreeToPaid: false,
        unpaidOtherToPaid: false,
        unpaidOtherToFree: false,
        // existing stripe customer switching plans
        paidFreeToOther: false,
        paidOtherToPaid: false,
        paidOtherToFree: false,
        // new stripe customer managing own plan that is paid
        unpaidOtherManage: false,
        // new stripe customer managing own plan that is free
        unpaidFreeManage: false,
        // existing stripe customer managing own plan that is paid
        paidOtherManage: false,
        contactUs: false,
      },
      checkout: {
        planName: "",
        loading: false,
      },
      portal: {
        loading: false,
      },
      redirectBackFromStripe: {
        operation: null,
        status: null,
        alertType: "default",
        polling: false,
      },
      planTabs: {
        active: "change-plan",
      }
    };
  },
  //
  // computed properties
  //
  computed: {
    getUsedTrialPeriod() {
      let periodStart = Date.parse(this.user.created_at);
      let today = Date.now();
      return Math.floor((today - periodStart) / (86400.0 * 1000.0));
    },
    getServiceEntitlements() {
      return this.tierLimits.userActionsAllowedServices
        .split(",")
        .filter((item) => item !== "jira")
        .map((item) => {
          if (item === "ui") {
            return "Dashboard";
          } else if (item === "interceptor") {
            return "Selenium and Cypress interceptors";
          } else if (item === "simulator") {
            return "App test simulator";
          } else if (item === "pageview-events") {
            return "App live-events";
          }
        })
        .join(", ");
    },
    getTrialPeriodDays() {
      return this.tierLimits.userActionsTrialDays > 0
        ? this.tierLimits.userActionsTrialDays
        : "unlimited";
    },
    getMaxVisibleTestRuns() {
      return this.tierLimits.testResultsMaxVisibleRuns > 0
        ? this.tierLimits.testResultsMaxVisibleRuns
        : "unlimited";
    },
    getVisibleHealedSelectors() {
      return this.tierLimits.testResultsHealedMaxItems > 0
        ? this.tierLimits.testResultsHealedMaxItems
        : "unlimited";
    },
    getVisibleSuggestedSelectors() {
      return this.tierLimits.testResultsSuggestedMaxItems > 0
        ? this.tierLimits.testResultsSuggestedMaxItems
        : "unlimited";
    },
    checkoutButtonDisabled() {
      if (this.checkout.planName === "") {
        return true;
      } else if (this.checkout.planName.toLowerCase() === this.user.tierName) {
        return true;
      } else {
        return this.checkout.loading;
      }
    }
  },
  //
  // lifecycle hooks
  //
  async created() {
    await this.getSubscriptionPlan();
  },
  mounted() {
    // get any status items from the URI
    const thisUri = new URL(window.location.href);
    let uriParams = thisUri.searchParams;
    this.redirectBackFromStripe.operation = uriParams.get("operation");
    this.redirectBackFromStripe.status = uriParams.get("status");
    // 5 seconds after returning from stripe, reload plan to get latest info
    if (this.redirectBackFromStripe.operation && this.redirectBackFromStripe.status) {
      setTimeout(() => {
        this.getSubscriptionPlan().then(() => {
          this.redirectBackFromStripe.operation = null;
          this.redirectBackFromStripe.status = null;
        });
      }, 5000);
    }
    if (thisUri.search !== "") {
      this.$router.push(thisUri.pathname);
    }
  },
  //
  // view methods
  //
  methods: {

    // awaitable setTimeout
    async awaitableTimeout(milliseconds) {
      return new Promise(resolve => setTimeout(resolve, milliseconds));
    },

    // formats the timestamp for a subscription event
    formatSubscriptionEventTimestamp(timestamp) {
      const currentTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return moment(timestamp).tz(currentTz).format("h:mm A z, MMMM Do YYYY");
    },

    // gets existing plan info for the user from the API
    async getSubscriptionPlan() {
      try {
        this.loading = true;
        let resp = await axios.get(`${process.env.VUE_APP_API_URL_PREFIX}/subscription/v1/plan/user`);
        if (resp.status === 200) {
          this.user = resp.data.response.user;
          this.allPlans = resp.data.response.tierSettings;
          this.tierLimits = resp.data.response.user.tierLimits;
          this.tierUsage = resp.data.response.user.tierUsage;
          this.stripe = resp.data.response.user.stripe;
          this.subscriptionEvents = resp.data.response.user.events;
        } else {
          this.$notify({
            type: "danger",
            message: "Could not load your subscription plan details. " +
              "If this error persists, contact support@wring.dev"
          });
        }
      } catch (e) {
        this.$notify({
          type: "danger",
          message: `Could not load your subscription plan details because of an error: ${e.message}` +
            "If this error persists, contact support@wring.dev.",
        });
      } finally {
        this.loading = false;
      }
    },

    // handles formatting of the plan name
    getPlanName(value) {
      return value.substring(0, 1).toUpperCase() + value.substring(1);
    },

    // handles formatting of plan pricing
    getPlanPricing(value) {
      let amount = value.pricing.amount;
      let currency = value.pricing.currency;
      let interval = value.pricing.interval;
      let unit = "seat";

      let intAmount = parseInt(amount);
      if (Number.isNaN(intAmount)) {
        return "Unknown";
      }

      if (intAmount < 0) {
        return "Get in touch for custom pricing!";
      } else if (intAmount === 0) {
        return "Free!";
      } else {
        return `${parseInt(amount) / 100} ${currency.toUpperCase()} per ${interval}`;
      }
    },

    // handles formatting of various plan features
    formatPlanFeature(feature, value, plan) {
      if (feature === "userActionsTrialDays" || feature === "testResultsMaxVisibleRuns") {
        return value > 0 ? value : "unlimited";
      } else if (feature === "maxTestBaseUrls") {
        if (this.allPlans[plan]["userActionsAllowedServices"].indexOf("pageview-events") === -1) {
          return "None"
        } else {
          return value;
        }
      } else if (feature === "userActionsAllowedServices") {
        return value
          .filter((item) => item !== "jira")
          .map((item) => {
            if (item === "ui") {
              return "Dashboard";
            } else if (item === "interceptor") {
              return "Selenium and Cypress interceptors";
            } else if (item === "simulator") {
              return "App test simulator";
            } else if (item === "pageview-events") {
              return "App live-events";
            }
          })
          .join(", ");
      } else if (feature.indexOf("Bytes") !== -1) {
        return value / (1024 * 1024);
      } else {
        return value;
      }
    },

    // handles changing the subscription plan
    changeSubscriptionPlan(event, planLabel) {
      this.checkout.planName = "";
      const currentPlanPricing = this.allPlans[this.user.tierName].pricing;
      const currentPlanIsPaid = parseInt(currentPlanPricing.amount) > 0;
      const userIsStripeCustomer = this.stripe !== null && this.stripe.stripe_customer_id !== null;
      const userHasValidSubscription = this.stripe !== null
        && this.stripe.stripe_sub_id !== null
        && this.stripe.stripe_sub_state === "current";
      const newPlanPricing = this.allPlans[planLabel].pricing;
      const newPlanIsPaid = parseInt(newPlanPricing.amount) > 0;

      console.log(
        `clicked on ${planLabel} button with ` +
        `price: ${newPlanPricing.amount} ${newPlanPricing.currency ? newPlanPricing.currency.toUpperCase() : ''}`
      );

      console.log(
        `userIsStripeCustomer = ${userIsStripeCustomer}, ` +
        `userHasValidSubscription = ${userHasValidSubscription}, ` +
        `currentPlanIsPaid = ${currentPlanIsPaid}, ` +
        `newPlanIsPaid = ${newPlanIsPaid}`
      );

      if (userHasValidSubscription && currentPlanIsPaid && newPlanIsPaid) {
        // existing stripe customer going from paid to paid
        this.checkout.planName = this.getPlanName(planLabel);
        this.modals.paidOtherToPaid = true;
        console.log("paidOtherToPaid");
      } else if (userHasValidSubscription && currentPlanIsPaid && !newPlanIsPaid) {
        // existing stripe customer going from paid to free
        this.modals.paidOtherToFree = true;
        console.log("paidOtherToFree");
      } else if (userHasValidSubscription && !currentPlanIsPaid && newPlanIsPaid) {
        // existing stripe customer going from free to paid
        this.modals.paidFreeToOther = true;
        console.log("paidFreeToOther");
      } else if (!userHasValidSubscription && !currentPlanIsPaid && newPlanIsPaid) {
        // new stripe customer going from free to paid
        this.checkout.planName = this.getPlanName(planLabel);
        this.modals.unpaidFreeToPaid = true;
        console.log("unpaidFreeToPaid");
      } else if (!userHasValidSubscription && currentPlanIsPaid && newPlanIsPaid) {
        // new stripe customer going from paid to paid
        this.checkout.planName = this.getPlanName(planLabel);
        this.modals.unpaidOtherToPaid = true;
        console.log("unpaidOtherToPaid");
      } else if (!userHasValidSubscription && currentPlanIsPaid && !newPlanIsPaid) {
        // new stripe customer going from paid to free
        this.modals.unpaidOtherToFree = true;
        console.log("unpaidOtherToFree");
      }

    },

    // handles managing the current plan
    manageCurrentPlan(event) {
      this.checkout.planName = "";
      const currentPlanPricing = this.allPlans[this.user.tierName].pricing;
      const currentPlanIsPaid = parseInt(currentPlanPricing.amount) > 0;
      const userIsStripeCustomer = this.stripe !== null && this.stripe.stripe_customer_id !== null;
      const userHasValidSubscription = this.stripe !== null
        && this.stripe.stripe_sub_id !== null
        && this.stripe.stripe_sub_state === "current";
      const currentPlanName = this.getPlanName(this.user.tierName);

      console.log("clicked on manage current plan button");

      console.log(
        `userIsStripeCustomer = ${userIsStripeCustomer}, ` +
        `userHasValidSubscription = ${userHasValidSubscription}, ` +
        `currentPlanIsPaid = ${currentPlanIsPaid}`
      );

      if (userHasValidSubscription && currentPlanIsPaid) {
        this.modals.paidOtherManage = true;
        console.log("paidOtherManage");
      } else if (userHasValidSubscription && !currentPlanIsPaid) {
        this.modals.paidFreeToOther = true;
        console.log("paidFreeToOther");
      } else if (!userHasValidSubscription && currentPlanIsPaid) {
        this.checkout.planName = currentPlanName;
        this.modals.unpaidOtherManage = true;
        console.log("unpaidOtherManage");
      } else if (!userHasValidSubscription && !currentPlanIsPaid) {
        this.modals.unpaidFreeManage = true;
        console.log("unpaidFreeManage");
      }

    },

    // handles clicking on the checkout button
    async handleCheckoutButton(event) {

      const newPlanName = this.checkout.planName;

      console.log(`clicked on checkout button with plan: ${newPlanName}`);
      this.checkout.loading = true;
      try {
        const resp = await axios.put(
          `${process.env.VUE_APP_API_URL_PREFIX}/subscription/v1/plan/user`,
          {
            tierName: newPlanName.toLowerCase(),
          }
        );
        if (resp.status === 200) {
          // check if the operation completed successfully
          if (resp.data.status === "success") {
            window.location.assign(resp.data.response.url);
          } else {
            this.$notify({
              type: "danger",
              message: `Could not go to Stripe Checkout because of an error: ${resp.data.message}`
            });
          }
        }

        else {
          this.$notify({
            type: "danger",
            message: `Could not go to Stripe Checkout because of an error: ${resp.data.message}`
          });
        }

      } catch (err) {
        this.$notify({
          type: "danger",
          message: "Could not redirect to Stripe for checkout. " +
            "If this error persists, contact support@wring.dev"
        });
      } finally {
        this.checkout.loading = false;
      }

    },

    // handles clicking on the manage plan button
    async handleManageButton(event) {
      console.log("clicked on manage button");

      const newPlanName = this.user.tierName;

      this.portal.loading = true;
      try {
        const resp = await axios.put(
          `${process.env.VUE_APP_API_URL_PREFIX}/subscription/v1/plan/user`,
          {
            tierName: newPlanName.toLowerCase(),
          }
        );
        if (resp.status === 200) {
          if (resp.data.status === "success") {
            window.location.assign(resp.data.response.url);
          } else {
            this.$notify({
              type: "danger",
              message: `Could not go to Stripe Billing Portal because of an error: ${resp.data.message}`
            });
          }
        }

        else {
          this.$notify({
            type: "danger",
            message: `Could not go to Stripe Billing Portal because of an error: ${resp.data.message}`
          });
        }

      } catch (err) {
        this.$notify({
          type: "danger",
          message: "Could not redirect to Stripe for checkout. " +
            "If this error persists, contact support@wring.dev"
        });
      } finally {
        this.portal.loading = false;
      }

    },

    // handles clicking on the switch-to-free button for an unpaid user
    handleUnpaidOtherToFreeButton(event) {
      console.log("clicked on switch-to-free button for unpaid user");

    },

    // handles formatting the redirect from Stripe alert message
    formatStripeRedirectMessage() {
      if (this.redirectBackFromStripe.operation === "checkout") {
        if (this.redirectBackFromStripe.status === "success") {
          this.redirectBackFromStripe.polling = true;
          return "Checkout succeeded! Your chosen subscription plan will be activated shortly."
        } else if (this.redirectBackFromStripe.status === "cancelled") {
          this.redirectBackFromStripe.alertType = "warning";
          return "Checkout has been cancelled. NO CHANGES HAVE BEEN MADE to your subscription plan."
        } else {
          this.redirectBackFromStripe.alertType = "danger";
          return "Unknown error when redirecting back from Stripe. Please contact support@wring.dev."
        }
      } else if (this.redirectBackFromStripe.operation === "portal") {
        this.redirectBackFromStripe.polling = true;
        return "If you made any changes to your subscription plan, they will be updated below shortly."
      } else {
        this.redirectBackFromStripe.alertType = "danger";
        return "Unknown error when redirecting back from Stripe. Please contact support@wring.dev."
      }
    },

    openContactUs(event) {
      this.modals.contactUs = true;
    }

  },


};
</script>

<style>
meter {
  width: 100%;
}

.active-plan {
  background-color: #cff4fc;
}

table.pricing-table {
  width: 100%;
}

.pricing-table thead th {
  font-size: larger;
}

th.white-space-normal {
  white-space: normal;
}

td.white-space-normal {
  white-space: normal;
}

.modal-text {
  font-weight: 400;
}

.subplan-tab-button-first {
  margin-right: 0.5em;
  margin-left: 0;
  margin-bottom: 0.5em;
}

.subplan-tab-button {
  margin-right: 0.5em;
  margin-left: 0.5em;
  margin-bottom: 0.5em;
}

.sub-current-plan-label {
  font-size: large;
}
</style>
